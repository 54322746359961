<template>
	<v-dialog v-model="dialog" persistent scrollable max-width="60vw" @close="$emit('close')">
		<v-card>
			<v-card-title class="secondary">
				<v-layout fluid align-center white--text>
					<v-flex v-if="isAccountingFirmTask" v-t="'client_workspace.task.create.accountingFirmTask'" headline></v-flex>
					<v-flex v-else-if="isInformationRequestTask" v-t="'client_workspace.task.create.informationRequestTask'" headline></v-flex>
					<v-spacer></v-spacer>
					<v-btn icon @click="$emit('close')">
						<v-icon class="white--text">close</v-icon>
					</v-btn>
				</v-layout>
			</v-card-title>
			<v-card-text>
				<v-container>
					<v-layout column>
						<v-flex v-if="isInformationRequestTask">
							<v-layout row>
								<v-icon class="font-italic mr-2" color="info" small>info</v-icon>
								<v-flex v-t="'tasks.create.information_request_info'" class="font-italic" />
							</v-layout>
						</v-flex>
						<v-flex shrink>
							<v-layout row>
								<v-flex xs6>
									<w-text-input v-model="task.title" :label="$t('tasks.title')" maxlength="191" />
								</v-flex>
								<v-flex xs3>
									<v-switch v-if="isAccountingFirmTask" v-model="isInternal" :label="$t('task.internal')" color="primary" />
								</v-flex>
								<v-flex v-if="!isInternal || isInformationRequestTask" xs3>
									<v-select
										v-model="task.vendor"
										:label="$t('client_workspace.task.companies')"
										:items="filteredCompanies"
										item-value="id"
										item-text="company"
										return-object>
										<template v-slot:prepend-item>
											<w-search-input v-model="search" hide-details single-line />
										</template>
									</v-select>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex shrink>
							<TasksManagerTaskContent v-model="task" />
						</v-flex>
					</v-layout>
				</v-container>
			</v-card-text>
			<v-card-actions>
				<v-layout row wrap>
					<v-spacer></v-spacer>
					<v-btn color="primary" flat @click="save()">
						<v-icon>save</v-icon>
						<span v-t="'actions.create'" class="ml-2" />
					</v-btn>
				</v-layout>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import TasksManagerModuleGuard from '@/mixins/ModulesGuards/TasksManager/TasksManagerModuleGuard'

export default {
	name: 'TasksManagerActionAddDialog',
	components: {
		TasksManagerTaskContent: () => ({
			component: import('@/components/TasksManager/Task/TasksManagerTaskContent')
		})
	},
	mixins: [TasksManagerModuleGuard],
	props: {
		parentTask: {
			type: Object,
			required: false,
			default: null
		},
		type: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			dialog: true,
			task: {
				title: '',
				description: '',
				note: '',
				vendor: null,
				members: [],
				themes: [],
				type: this.type
			},
			isInternal: false,
			search: ''
		}
	},
	computed: {
		...mapState({
			companies: state => state.tasks.companies,
			accountants: state => state.tasks.members,
			userId: state => state.user.id,
			themes: state => state.tasks.themes,
			filters: state => state.tasks.filters,
		}),
		isAccountingFirmTask: function () {
			return this.service.isOfTaskAccountingFirmType(this.task)
		},
		isInformationRequestTask: function () {
			return this.service.isOfTaskInformationRequestType(this.task)
		},
		taskCompany: function () {
			return this.task.vendor
		},
		filteredCompanies: function () {
			const search = this.search ? this.search.trim().toLowerCase() : ''
			return this.companies
				.filter(company => company.company.toLowerCase().includes(search))
		}
	},
	watch: {
		isInternal: {
			handler: function (newValue) {
				if (newValue) {
					this.task.vendor = null
					this.task.themes.clear()
					this.task.members.push(this.userId)
				} else {
					this.task.vendor = (this.filters && this.filters.companies && this.filters.companies[0]) ? this.companies.filter(company => company.id == this.filters.companies[0])[0] : this.companies[0]
					this.task.themes = this.themes
					this.task.members.clear()
				}
			}
		},
		taskCompany: {
			handler: function (newValue) {
				if (newValue && this.task.vendor.themes) {
					this.task.themes = [...this.task.vendor.themes.map(theme => theme.id)]
				} else {
					this.task.themes.clear()
				}
			}
		}
	},
	created: function () {
		if (this.parentTask) {
			this.task.task_id = this.parentTask.id
		}
		// Insure a company is selected
		this.task.vendor = (this.filters && this.filters.companies && this.filters.companies[0]) ? this.companies.filter(company => company.id == this.filters.companies[0])[0] : this.companies[0]
		this.task.themes = this.task.vendor.themes ? [...this.task.vendor.themes.map(theme => theme.id)]: []
		// Insure static data are loaded
		return Promise.all([this.service.loadStatuses(), this.service.loadMembers()])
	},
	methods: {
		save: function () {
			this.appEventBus.emit(this.appEvents.UPDATE_LOADING_STATUS, true)
			let result
			this.task.due_date = this.task?.due_date?.substring(0, 10)
			if (this.isInformationRequestTask && !this.task.task_id) {
				result = this.service.findVendorInformationRequestHoldingTask(this.task.vendor.id).then(parentTask => {
					if (!parentTask) {
						parentTask = {
							title: 'tasks.type.' + this.service.getTaskInformationRequestType().name,
							vendor: this.task.vendor,
							themes: this.task.themes,
							type: this.service.getTaskAccountingFirmType()
						}
						return this.service.createTask(parentTask).then(createdParentTask => {
							this.task.task_id = createdParentTask.id
							return this.service.createTask(this.task)
						})
					} else {
						this.task.task_id = parentTask.id
						return this.service.createTask(this.task)
					}
				})
			} else {
				result = this.service.createTask(this.task)
			}
			result
				.then(newTask => {
					this.service.selectTask(newTask)
					this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('tasks.created'))
				})
				.catch(error => {
					this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, error)
				})
				.finally(() => {
					this.$emit('close')
					this.appEventBus.emit(this.appEvents.UPDATE_LOADING_STATUS, false)
				})
		}
	}
}
</script>
